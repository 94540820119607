.footer-box {
  font-family: "Myriad Pro";
  background: $c_menu_bg;
  color: $c_color;
  height: 2em;
  padding-top: 0.25em;
  .footer-inner-box {
    display: flex;
    justify-content: space-between;
    a {
      color: $c_color;
    }
  }
}
