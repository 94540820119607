.calendar-box {
  position: relative;
  padding: 15px 1em;
  background: #ffffffa0;
  min-height: calc(100vh - 4.5em);

  @include media-breakpoint-only(lg) {
    & {
      min-height: calc(100vh - 5.57em);
    }
  }

  .shape {
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.14);
    border-radius: 0.3em;
  }

  .legend-button {
    padding: 0.2rem 0.5rem;
    background: linear-gradient(180deg, $c_gradient_from, $c_gradient_to);
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    border-color: $c_navlink_border;
    color: white;
    cursor: pointer;

    &:hover {
      background: $c_navlink_hover;
    }

    &.active {
      box-shadow: inset 1px 1px 5px 1px rgba(0, 0, 0, 0.66);
    }
  }

  .legend-box {
    margin: 1em;
    .legend {
      @extend .shape;
      background-color: #ffffff;
      // padding: 1em;
      .legend-tile {
        @extend .shape;
        padding: 0.4em;
        margin: 0.4em;
        &.sowing-expected {
          background: $c_calendar_sowing_expected_bg;
          color: $c_calendar_sowing_expected_text;
        }
        &.sowing-date {
          background: $c_calendar_sowing_date_bg;
          color: $c_calendar_sowing_date_text;
        }
        &.maturation-forecast {
          background: $c_calendar_maturation_bg;
          color: $c_calendar_maturation_text;
        }
        &.harvesting {
          background: $c_calendar_harvesting_bg;
          color: $c_calendar_harvesting_text;
        }
        &.weekend {
          color: black;
          &::after {
            position: relative;
            display: inline-block;
            width: 1em;
            border-bottom: 0.3em solid $c_calendar_weekend_bg;
            border-left: 0.25em solid transparent;
            border-right: 0.25em solid transparent;
            transform: rotate(45deg);
            margin: -0.4em -0.65em 1.1em -0.3em;
            // margin-top: 0.2em;
            // margin-right: -0.4em;
            // border-top-style: solid;
            // border-left-style: solid;
            // border-top-width: 0.7em;
            // border-left-width: 0.7em;
            // border-top-color: $c_calendar_weekend_bg;
            // border-right-color: $c_calendar_weekend_bg;
            // border-bottom-color: #00000000;
            // border-left-color: #00000000;
            content: "";
          }
        }
      }
    }
  }

  .calendar-spinner {
    display: flex;
    height: 50vh;
    justify-content: center;
    align-items: center;
  }

  .mobile {
    display: none !important;
  }

  @include media-breakpoint-down(md) {
    .mobile {
      display: block !important;
    }
    .desktop {
      display: none !important;
    }
    .mobile-row {
      display: flex;
      flex-direction: row;
    }
  }

  .region-box {
    padding: 1em 0 0 0;

    .region {
      @extend .shape;
      background: #86c4ed;
      color: #000000;
      padding: 1em;
      text-align: center;
    }

    .district-box {
      padding: 1em 0 0 1em;
      .district {
        @extend .shape;
        background: #86c4ed;
        color: #000000;
        padding: 0.6em;
      }

      .town-box {
        padding: 0.5em 0 0 0.5em;
        margin: 0;
        .header-box {
          padding: 0 0 0 0.5em;
          .header {
            @extend .shape;
            display: flex;
            align-items: center;
            background: #86c4ed;
            color: #000000;
            padding: 0.6em;
          }
          .tile {
            @extend .shape;
            margin: 0.4em 0;
            width: 100%;
            line-height: 1;
            padding: 0.6em;
            background: #ffffff;
            color: #1c4a79;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .month-box {
              .month-label {
                padding-top: 0.5em;
                text-align: center;
                text-transform: capitalize;
              }
              .dates-box {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                max-width: 650px;
                // justify-content: center;
                .day {
                  @extend .shape;
                  position: relative;
                  width: 2.5em;
                  height: 2.5em;
                  margin: 0.15em;
                  background: #ffffff;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  &.sowing-expected {
                    background: $c_calendar_sowing_expected_bg;
                    color: $c_calendar_sowing_expected_text;
                  }
                  &.sowing-date {
                    background: $c_calendar_sowing_date_bg;
                    color: $c_calendar_sowing_date_text;
                  }
                  &.maturation-forecast {
                    background: $c_calendar_maturation_bg;
                    color: $c_calendar_maturation_text;
                  }
                  &.harvesting {
                    background: $c_calendar_harvesting_bg;
                    color: $c_calendar_harvesting_text;
                  }
                  &.weekend {
                    &:after {
                      position: absolute;
                      top: 0.1em;
                      right: -0.25em;
                      display: inline-block;
                      width: 1em;
                      border-bottom: 0.3em solid $c_calendar_weekend_bg;
                      border-left: 0.3em solid transparent;
                      border-right: 0.3em solid transparent;
                      transform: rotate(45deg);
                      content: "";
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
