.main-box {
  min-height: calc(100vh - 4.5em);

  @include media-breakpoint-only(lg) {
    & {
      min-height: calc(100vh - 5.57em);
    }
  }
  .head {
    color: #ffffff;
    line-height: 2em;

    .head-title {
      display: flex;
      .kgm-logo {
        height: 6em;
        margin: 1.5em 2em 1em 1.2em;
      }

      .title {
        @extend .text-shadow;
        max-width: 870px;
        font-size: 1.8em;
        text-align: start;
        margin: 0px auto;
        padding-top: 1.2em;
        font-family: Arial;
      }
    }

    .subtitle {
      @extend .text-shadow;
      font-size: 1.5em;
      text-align: center;
      margin: 0px auto;
      padding-top: 1em;
      font-family: Arial;
    }
  }
  .content {
    background-color: #ebebebaa;
    padding: 1em;
    margin-top: 1em;
    .header {
      @extend .text-shadow;
      color: $c_color;
      line-height: 1em;
      font-size: 1.8em;
      padding-bottom: 0.5em;
    }
    .image {
      border-radius: 0.5em;
      width: 100%;
    }
  }
  .content-start {
    @extend .content;
    border-radius: 0.5em 0.5em 0 0;
    margin-top: 2em;
    .block {
      padding: 0.5em 0 0 1em;
      font-family: Arial;
      font-style: italic;
      font-weight: normal;
      line-height: 1.5em;
      color: #1f1f1f;
      font-size: 1.2em;
      padding-bottom: 1.2em;
    }
    .col-right {
      display: flex;
      flex-direction: column;
      .image-box1 {
        flex: 1;
        .image-title {
          color: #1f1f1f;
          text-align: center;
          max-width: 374px;
          margin: 0px auto;
          font-family: Arial;
          font-style: italic;
          font-weight: normal;
          line-height: 21px;
          padding-top: 3px;
        }
      }
    }
  }
  .ck-content {
    color: #1f1f1f;
    font-size: 1.2em;
    h1,
    h2,
    h3,
    h4 {
      @extend .text-shadow;
    }
    .image > figcaption {
      font-size: 0.9em;
      background: none;
    }
  }
  .content-end {
    @extend .content;
    border-radius: 0 0 0.5em 0.5em;
    margin-bottom: 1em;
    .block {
      color: #1f1f1f;
      text-align: center;
      padding: 1em 0;
      line-height: 1.2em;
      font-family: Arial;
      font-style: italic;
      font-size: 1.2em;
    }
    .block1 {
      @extend .block;
    }
    .block2 {
      @extend .block;
    }
    .block3 {
      @extend .block;
      font-weight: bold;
    }
    .col2 {
      display: flex;
      align-items: center;
    }
  }
  .loading-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
  }

  @include media-breakpoint-down(xl) {
    & {
      font-size: 1rem;
      .ck-content {
        h4 {
          font-size: 1.3em;
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    & {
      font-size: 1.4vw;
    }
  }

  @include media-breakpoint-down(md) {
    & {
      font-size: 1.5vw;
    }
  }

  @include media-breakpoint-down(sm) {
    & {
      font-size: 2vw;
    }
  }

  @include media-breakpoint-down(xs) {
    & {
      font-size: 2.5vw;
    }
  }
}
