.actual-ndvi-box {
  background: $c_bg;
  min-height: 100%;
  padding: 1em;
  min-height: calc(100vh - 4.5em);

  @include media-breakpoint-only(lg) {
    & {
      min-height: calc(100vh - 5.57em);
    }
  }

  .item-box {
    color: $c_color_bg;
    margin-bottom: 1em;
  }

  .spinner-box {
    position: absolute;
    display: flex;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    // height: 50vh;
    justify-content: center;
    align-items: center;
  }

  .header {
    color: $c_color_bg;
    font-size: 1.3em;
    padding: 1em;
  }
}
