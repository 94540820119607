.actual-moisture-box {
  background: #ffffffa0;
  min-height: calc(100vh - 4.5em);

  @include media-breakpoint-only(lg) {
    & {
      min-height: calc(100vh - 5.57em);
    }
  }

  .moisture-spinner {
    position: absolute;
    display: flex;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    // height: 50vh;
    justify-content: center;
    align-items: center;
  }

  .select-box {
    padding: 0.5em;
  }

  .map-box {
    // flex-grow: 1;
    overflow: hidden;
    height: 50vh;
  }

  @include media-breakpoint-down(md) {
    .map-box {
      display: none;
    }
  }

  .widget-box {
    padding: 0.5em;
    color: $c_color_bg;
    .card {
      margin: 0.5em;
    }
    .date-range {
      margin-left: 1em;
      margin-bottom: 0.5em;
      font-size: 1.2em;
    }
  }
}
