.spinner-box {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: #00000070;
  z-index: 999999999;
  .spinner {
  }
  .text {
    margin-top: 1em;
    height: auto;
    display: flex;
    width: 12em;
    color: #ffffff;
    white-space: normal;
    text-align: center;
  }
}
