.weather-main-widget-box {
  border-radius: 5px;
  line-height: 1;
  font-size: 3vw;
  width: 100%;
  padding: 1em;
  color: #ffffff;

  .info {
    width: 100%;
    border-style: solid;
    border-width: 1px;
    border-color: #ffffffb4;
    border-radius: 7px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.65);
    padding: 0;
    margin: 0;
  }

  .up-row {
    @extend .info;

    display: flex;
    flex-direction: row;

    .weather-icon {
      height: 4em;
      width: 4em;
      opacity: 1;
    }

    .divider {
      margin: 0;
      padding: 0;
      border-left-style: solid;
      border-left-width: 1px;
      border-left-color: #ffffff80;
      width: 0;
    }

    .temperature-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0.2em;

      &.flex-left {
        align-items: flex-start;
      }

      .temperature {
        font-size: 2em;
        font-family: "Source Sans Pro";
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.4);
      }

      .temp-inline {
        display: flex;
        flex-direction: row;
        white-space: nowrap;
        font-size: 1em;
        line-height: 1.4;
      }

      .temperature-soil-box {
        font-size: 0.4em;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.4);
        margin-top: 0.5em;
        display: flex;
        .temperature-soil-label {
          margin-right: 0.5em;
        }
        .temperature-soil {
          font-size: 2.1em;
        }
      }
    }

    .city-name-box {
      width: 100%;
      padding: 0.5em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;

      .city-name {
        padding-bottom: 0.3em;
        font-size: 0.95em;
        font-family: "Myriad Pro";
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.4);
        height: 1.08em;
        overflow: hidden;
      }

      .date {
        line-height: 1.2;
        font-size: 0.7em;
        font-family: "Myriad Pro";
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.4);
        &::first-letter {
          text-transform: capitalize;
        }
      }
    }
  }

  .values-row {
    display: flex;
    padding: 0;
    margin: 0.6em 0 0 0;

    .component-box {
      @extend .info;
      padding: 0.3em;
    }

    .component-box-margin {
      margin-right: 0.6em;
    }

    .component-box-wider {
      flex-basis: 150%;
    }

    .component-label {
      font-family: "Myriad Pro";
      font-size: 0.5em;
      padding: 0;
      margin: 0.2em 0 0 0.6em;
    }

    .component-icon {
      width: 1em;
      height: 1em;
    }

    .component-value {
      display: flex;
      font-family: "Myriad Pro";
      font-size: 1em;
      padding: 0;
      margin: 0.2em 0 0 0;
      justify-content: center;
      // white-space: nowrap;

      .humidity-unit-text {
        font-size: 1em;
      }

      .pressure-unit-text {
        width: 2.7em;
        margin-left: 0.3em;
        font-size: 0.44em;
      }

      .wind-unit-text {
        font-size: 0.45em;
      }

      .wind-direction-icon {
        transform: rotate(90deg);
        margin: -0.15em 0.15em 0 0;
        height: 0.9em;
        width: 0.9em;
      }
    }
  }
}

.weather-main-widget-box.forecast {
  font-size: 1.5vw;
  .component-box {
    font-size: 2.3vw;
  }
}

@include media-breakpoint-only(xl) {
  .weather-main-widget-box {
    font-size: 2.1em;
  }

  .weather-main-widget-box.forecast {
    font-size: 1.1em;

    .component-box {
      font-size: 1.5em;
    }
  }
}

@include media-breakpoint-only(lg) {
  .weather-main-widget-box {
    font-size: 2.8vw;
  }

  .weather-main-widget-box.forecast {
    font-size: 1.6vw;

    .component-box {
      font-size: 2.2vw;
    }
  }
}

@include media-breakpoint-only(md) {
  .weather-main-widget-box {
    font-size: 3.4vw;
  }

  .weather-main-widget-box.forecast {
    font-size: 2.4vw;

    .component-box {
      font-size: 3.2vw;
    }
  }
}

@include media-breakpoint-only(sm) {
  .weather-main-widget-box {
    font-size: 4.2vw;
  }
  .weather-main-widget-box.forecast {
    font-size: 3.6vw;

    .component-box {
      font-size: 4.8vw;
    }
  }
}

@include media-breakpoint-only(xs) {
  .weather-main-widget-box {
    font-size: 5vw;
  }
  .weather-main-widget-box.forecast {
    font-size: 4.4vw;

    .component-box {
      font-size: 6.6vw;
    }
  }
}

.weather-clear {
  background: url("../../../assets/img/weather/ClearBg.png");

  .weather-bg-color {
    background: linear-gradient(0deg, #0051d7b4, #75b9efb4);
  }
}

.weather-cloudy {
  background: url("../../../assets/img/weather/CloudyBg.png");

  .weather-bg-color {
    background: linear-gradient(0deg, #5d666db4, #8e8689b4);
  }
}

.weather-clear-cloudy {
  background: url("../../../assets/img/weather/ClearCloudyBg.png");

  .weather-bg-color {
    background: linear-gradient(0deg, #0661a2b4, #3d88c3b4);
  }
}

.weather-clear-cloudy-precipitation {
  background: url("../../../assets/img/weather/ClearCloudyPrecipitationBg.png");

  .weather-bg-color {
    background: linear-gradient(0deg, #5d666db4, #8e9699b4);
  }
}

.weather-clear-cloudy-rain {
  background: url("../../../assets/img/weather/ClearCloudyRainBg.png");

  .weather-bg-color {
    background: linear-gradient(0deg, #5d6678b4, #8e96a4b4);
  }
}

.weather-clear-cloudy-snow {
  background: url("../../../assets/img/weather/ClearCloudySnowBg.png");

  .weather-bg-color {
    background: linear-gradient(0deg, #0661a2b4, #3d88c3b4);
  }
}

.weather-clear-cloudy-storm {
  background: url("../../../assets/img/weather/ClearCloudyStormBg.png");

  .weather-bg-color {
    background: linear-gradient(0deg, #187ad9b4, #48aaffb4);
  }
}

.weather-cloudy-rain {
  background: url("../../../assets/img/weather/CloudyRainBg.png");

  .weather-bg-color {
    background: linear-gradient(0deg, #4d565db4, #7e8689b4);
  }
}

.weather-cloudy-rain-storm {
  background: url("../../../assets/img/weather/CloudyRainStormBg.png");

  .weather-bg-color {
    background: linear-gradient(0deg, #4d565db4, #7e8689b4);
  }
}

.weather-cloudy-snow {
  background: url("../../../assets/img/weather/CloudySnowBg.png");

  .weather-bg-color {
    background: linear-gradient(0deg, #0f1226b4, #38334cb4);
  }
}

.weather-clear-night {
  background: url("../../../assets/img/weather/ClearNightBg.png");

  .weather-bg-color {
    background: linear-gradient(0deg, #0f1226b4, #38334cb4);
  }
}

.weather-clear-cloudy-night {
  background: url("../../../assets/img/weather/ClearCloudyNightBg.png");

  .weather-bg-color {
    background: linear-gradient(0deg, #0f1226b4, #38334cb4);
  }
}

.weather-clear-cloudy-rain-night {
  background: url("../../../assets/img/weather/ClearCloudyRainNightBg.png");

  .weather-bg-color {
    background: linear-gradient(0deg, #0f1226b4, #38334cb4);
  }
}
