.drought-box {
  padding: 0;
  height: 100%;
  background: #ffffffa0;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 4.5em);
  @include media-breakpoint-only(xs) {
    @supports (-webkit-touch-callout: none) {
      height: calc(100vh - 4.5em - 4.5em);
    }
  }

  @include media-breakpoint-only(lg) {
    & {
      height: calc(100vh - 7.57em);
    }
  }

  .drought-spinner {
    position: absolute;
    display: flex;
    width: 100%;
    height: 50vh;
    justify-content: center;
    align-items: center;
  }

  .legend-box {
    max-height: 100%;
    overflow: auto;
    font-size: 0.8em;
    .legend-button {
      position: absolute;
      z-index: 1;
      margin: 0.5em;
      display: inline-block;
      padding: 0.2rem 0.5rem;
      background: linear-gradient(180deg, $c_gradient_from, $c_gradient_to);
      border-width: 1px;
      border-style: solid;
      border-radius: 5px;
      border-color: $c_navlink_border;
      color: white;
      cursor: pointer;

      &:hover {
        background: $c_navlink_hover;
      }

      &.active {
        box-shadow: inset 1px 1px 5px 1px rgba(0, 0, 0, 0.66);
      }
    }

    .collapse {
      padding-top: 2.5em;
    }

    .legend-header {
      color: #101010;
      font-size: 1.2em;
      padding: 1em;
      padding-bottom: 0;
      padding-left: 1.2em;
    }

    .legend {
      // padding: 0 1em;
      margin: 0;
      .legend-tile {
        font-size: 1.2em;
        display: flex;
        align-items: center;
        padding: 1em;
        .text {
          font-size: 0.8em;
          color: #101010;
          margin-left: 0.5em;
          white-space: normal;
        }
      }
    }
  }

  .map-box {
    flex-grow: 1;
    overflow: hidden;
    height: 100%;
  }

  .header {
    position: absolute;
    z-index: 1000;
    margin: 0.4em 0.4em 0.4em 13em;
    padding: 0 0.4em;
    color: #000000;
    border-radius: 0.4em;
    background: #ffffff;
    box-shadow: 0 0 0.2em #ffffff;
    font-size: 1.2em;
    font-weight: bold;
  }

  @include media-breakpoint-down(md) {
    .header {
      display: none;
    }
  }
}

@include media-breakpoint-up(lg) {
  .drought-box {
    flex-direction: row;
    .width {
      overflow: hidden;
      width: 0;
      transition-timing-function: ease;
      transition-duration: 300ms;
      transition-property: width;
    }
    .width.show {
      width: 19em;
    }
    .collapse-content {
      width: 19em;
    }
  }
}
