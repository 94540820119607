.client-text-box {
  color: $c_text;
  font-size: 1rem;
  margin-bottom: 1em;
  .loading-box {
    display: flex;
    height: 5em;
    align-items: center;
    justify-content: center;
  }
  .head {
    @extend .text-shadow;
    color: $c_color;
    line-height: 2em;
    font-size: 1.5em;
    text-align: center;
    margin: 0px auto;
    padding-top: 1.2em;
    font-family: Arial;
  }
  .content {
    min-height: 100%;
    padding: 1em;
    background: $c_bg;
    border-radius: 0 0 0.5em 0.5em;
  }

  @include media-breakpoint-down(xl) {
    & {
      font-size: 1rem;
      .ck-content {
        h4 {
          font-size: 1.3em;
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    & {
      font-size: 1.4vw;
    }
  }

  @include media-breakpoint-down(md) {
    & {
      font-size: 1.5vw;
    }
  }

  @include media-breakpoint-down(sm) {
    & {
      font-size: 2vw;
    }
  }

  @include media-breakpoint-down(xs) {
    & {
      font-size: 2.5vw;
    }
  }
}
