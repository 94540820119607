.page-box {
  padding: 15px 1em;
  background: #ffffffa0;
  min-height: 100%;
  color: #000000;
}

.loading-box {
  display: flex;
  align-items: center;
  justify-content: center;
  // height: 20vh;
}

.ck-custom {
  .ck-content {
    color: #1f1f1f;
    font-size: 1.2em;
    h1,
    h2,
    h3,
    h4 {
      @extend .text-shadow;
    }
    .image > figcaption {
      font-size: 0.9em;
      background: none;
    }
  }
}
