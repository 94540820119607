.weather-box {
  padding: 0.7em;
  background: #ffffffa0;
  color: #404040;
  min-height: calc(100vh - 4.5em);

  @include media-breakpoint-only(lg) {
    & {
      min-height: calc(100vh - 5.57em);
    }
  }

  .town-box {
  }

  .main-widget-box {
    margin-top: 1em;
    display: flex;
    justify-content: center;
    .main-widget {
    }
  }

  .model-box {
    display: flex;
    flex-direction: column;
    margin-top: 1.5em;
    color: $c_color_inverse;
    border-style: solid;
    border-width: 1px;
    border-color: #ffffffb4;
    border-radius: 7px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.65);
    background: linear-gradient(0deg, #e6e6f690, #ffffff90);
    .model-header-text {
      cursor: pointer;
      border-bottom: solid #00000010 1px;
      padding: 1em;
      text-align: center;
      .header {
        font-size: 1.5em;
      }
      .sub-header {
        font-size: 0.9em;
      }
      &:hover {
        background: #f6f6ff90;
      }
    }
    .model-content-box {
      display: flex;
      font-size: 1em;
      align-items: center;
      overflow-x: auto;

      @include media-breakpoint-up(md) {
        & {
          overflow-x: auto;
        }
      }

      .wrf-date-box {
        text-align: center;
        border-right: solid 1px #00000010;
        .wrf-box {
          display: flex;
          .model-widget {
            padding: 0.5em;
          }
        }
      }
    }
  }

  .region-name {
    // @extend .tile;
    color: #404040;
    margin: 2vw 0 0 0;
    padding: 0.5em 0.5em 0.5em 1em;
    font-size: 2em;
  }

  @include media-breakpoint-only(xl) {
    & {
      font-size: 1rem;
      .town-box {
        max-width: 30em;
        padding-left: 4em;
      }
      .main-widget {
        max-width: 40em;
      }
    }
  }

  @include media-breakpoint-only(lg) {
    & {
      font-size: 1.75vw;
      .town-box {
        max-width: 30em;
        padding-left: 4em;
      }
      .main-widget{
        max-width: 30em;
      }
    }
  }

  @include media-breakpoint-only(md) {
    & {
      font-size: 2vw;
      .town-box {
        max-width: 30em;
        padding-left: 2em;
      }
      .main-widget{
        max-width: 30em;
      }
    }
  }

  @include media-breakpoint-only(sm) {
    & {
      font-size: 2.25vw;
    }
  }

  @include media-breakpoint-only(xs) {
    & {
      font-size: 2.5vw;
    }
  }

  .text {
    // @extend .tile;
    white-space: normal;
    margin-top: 1em;
    padding: 1.5em;
    color: #404040;
  }

  .meteogram-box {
    padding-bottom: 3em;
    .title {
      font-size: 2em;
      padding-left: 2em;
    }
    .date {
      padding-left: 4em;
    }
    .image {
      width: 100%;
    }
  }
}
