.contracts-box {
  padding: 15px 1em;
  background: #ffffffa0;
  min-width: 50em;
  min-height: calc(100vh - 4.5em);

  @include media-breakpoint-only(lg) {
    & {
      min-height: calc(100vh - 5.57em);
    }
  }

  .no-contracts-text {
    color: $c_color_inverse;
  }

  .per-page-box {
    display: flex;
    .pp-label {
      font: $dark;
    }
  }

  .files-box {
    padding: 0;
    .files-toggle {
      margin: 0.2em;
    }
    .files-item {
      padding: 0.15rem 0.5rem;
      margin: 5px;
      background: linear-gradient(180deg, $c_gradient_from, $c_gradient_to);
      border-width: 1px;
      border-style: solid;
      border-radius: 5px;
      border-color: $c_navlink_border;
      color: white;
      cursor: pointer;

      &:hover {
        background: $c_navlink_hover;
      }
    }
  }
}
