.contract-box {
  .contract-disabled {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1000000;
  }
  .town-header {
    min-width: 20em;
  }
}
