$c_bg: #ebebebaa;
$c_text: #404040;

$c_menu_bg: #044a7b;
$c_gradient_from: #3c88c3;
$c_gradient_to: #0762a3;
$c_navlink_border: #53acdb;
$c_navlink_hover: #3b9ad9;
$c_calendar_header_bg: #76c5f0;
$c_calendar_header_color: #000000;
$c_calendar_first_color: #064878;
$c_color: #ffffff;
$c_color_bg: #404040;
$c_color_inverse: #101010;

$c_calendar_sowing_expected_bg: #aae0ff;
$c_calendar_sowing_expected_text: #000000;
$c_calendar_sowing_date_bg: #d4e9c2;
$c_calendar_sowing_date_text: #000000;
$c_calendar_maturation_bg: #8cb87b;
$c_calendar_maturation_text: #000000;
$c_calendar_harvesting_bg: #ee8a3f;
$c_calendar_harvesting_text: #000000;
$c_calendar_weekend_bg: #944e4e;
$c_calendar_weekend_text: #ffffff;

$c_calendar_maturation_date: #000000;

.btn-primary {
  background: linear-gradient(180deg, $c_gradient_from, $c_gradient_to);
  transition: none;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  border-color: $c_navlink_border;

  &:hover {
    background: $c_navlink_hover;
  }

  &.active {
    box-shadow: inset 1px 1px 5px 1px rgba(0, 0, 0, 0.66);
  }
}

.text-shadow {
  text-shadow: 0.07em 0.07em 0.1em $c_text;
}

html {
  background-color: $c_menu_bg;

  .app-bg {
    font-family: "Myriad Pro";
    position: fixed;
    width: 100%;
    height: 100%;
    background-image: url("./assets/img/bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: center;
  }

  .route-box {
    position: relative;
    padding-top: 2.5em;
    padding-bottom: 2em;
  }

  @include media-breakpoint-only(lg) {
    .route-box {
      padding-top: 3.57em;
    }
  }
}
