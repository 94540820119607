@import "~bootstrap/scss/bootstrap";
@import "~leaflet/dist/leaflet.css";

@import "./shared/components/ckeditorPage/ckeditor.css";

@import "./App.scss";

@import "./shared/components/scrolled/scrolled.scss";
@import "./shared/components/plain/spinner/spinner.scss";
@import "./shared/components/plain/yapoint/yapoint.scss";
@import "./shared/components/plain/yapolygon/yapolygon.scss";
@import "./shared/components/ckeditorPage/ckeditorPage.scss";
@import "./shared/components/footer/footer.scss";

@import "./routes/navbar/navbar.scss";
@import "./routes/actualMoisture/actualMoisture.scss";
@import "./routes/actualNdvi/actualNdvi.scss";
@import "./routes/calendar/calendar.scss";
@import "./routes/contracts/contracts.scss";
@import "./routes/contracts/contract.scss";
@import "./routes/contracts/contractPdf.scss";
@import "./routes/contracts/service.scss";
@import "./routes/balance/balanceChanges.scss";
@import "./routes/forecasts/droughtProductuvity.scss";
@import "./routes/forecasts/moisture.scss";
@import "./routes/login/login.scss";
@import "./routes/mainPage/aboutUs.scss";
@import "./routes/mainPage/page.scss";
@import "./routes/mainPage/instruction.scss";
@import "./routes/weather/weatherPage.scss";
@import "./routes/weather/widget/modelWidget.scss";
@import "./routes/weather/widget/widget.scss";
@import "./routes/client/text.scss";
@import "./routes/client/ndvi.scss";
