.contract-pdf-box {
  .contract-spinner {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1000000;
  }
  .pdf-document {
    font-size: 2em;
    .pdf-page {
      box-shadow: 0.1em 0.1em 0.2em #00000080;
      margin-bottom: 0.5em;
    }
  }
  .signed {
    
  }
}
