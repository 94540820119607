.point-box {
  width: 1.5em;
  min-width: 1.5em;
  max-width: 1.5em;
  height: 1.5em;
  min-height: 1.5em;
  max-height: 1.5em;
  background-color: #ffffff;
  border-radius: 0.75em;
  padding: 1px;
  .point-radius {
    width: 100%;
    height: 100%;
    border-radius: 0.75em;
    border-width: 0.2em;
    border-style: solid;
    padding: 0.25em;
    .point {
      width: .47em;
      height: .47em;
      border-radius: .3em;
    }
  }
}
