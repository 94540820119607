.aboutus-scroll-box {
  height: 100%;
  overflow: auto;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  color: #1f1f1f;
  min-height: calc(100vh - 4.5em);

  @include media-breakpoint-only(lg) {
    & {
      min-height: calc(100vh - 5.57em);
    }
  }
  .text-shadow {
    text-shadow: 0.05em 0.05em 0.13em #000000;
  }
  .about-us-box {
    padding: 1em;
    background-color: #ffffff99;
    flex: 1;
    .ck-content {
      color: #1f1f1f;
      h1,
      h2,
      h3,
      h4 {
        @extend .text-shadow;
      }
    }
  }

  @include media-breakpoint-down(xl) {
    & {
      font-size: 1rem;
    }
  }

  @include media-breakpoint-down(lg) {
    & {
      font-size: 1.4vw;
    }
  }

  @include media-breakpoint-down(md) {
    & {
      font-size: 1.8vw;
    }
  }

  @include media-breakpoint-down(sm) {
    & {
      font-size: 2.2vw;
    }
  }

  @include media-breakpoint-down(xs) {
    & {
      font-size: 3vw;
    }
  }
}
