.my-navbar {
  background: $c_menu_bg;
  color: $c_color;
  padding: 0px;

  .icon-box {
    padding: 0;
    margin-left: 1rem;
  }

  .my-collapse {
    max-width: 100%;
    overflow-y: visible; //todo this code does not work, navbar does not scroll on a small screen
  }

  .mobile-label {
    display: none;
  }

  @include media-breakpoint-down(md) {
    .mobile-label {
      display: block;
    }
  }

  .my-navlink {
    padding: 0.15rem 0.5rem;
    margin: 5px;
    background: linear-gradient(180deg, $c_gradient_from, $c_gradient_to);
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    border-color: $c_navlink_border;
    color: white;
    cursor: pointer;

    &:hover {
      background: $c_navlink_hover;
    }

    &.active {
      box-shadow: inset 1px 1px 5px 1px rgba(0, 0, 0, 0.66);
    }
  }
}

.text {
  font-family: "Myriad Pro";
  font-weight: bold;
  color: white;
  white-space: nowrap;
  height: 100%;
  display: flex;
  align-items: center;
}

@include media-breakpoint-only(xs) {
  .text {
    white-space: normal;
  }
}

.dropdown {
  .dropdown-toggle {
    padding: 0.4em;
    display: flex;
    align-items: center;
    .text {
      line-height: 1;
      white-space: normal;
    }
  }
}

.show,
.dropdown {
  .dropdown-menu.show {
    left: 5px;
    background: rgba($c_menu_bg, 0.8);
    padding: 0 10px 0 0;
  }
}
