.instruction-box {
  overflow: auto;
  font-size: 1rem;
  background-color: $c_bg;
  padding-top: 1em;
  min-height: calc(100vh - 4.5em);

  @include media-breakpoint-only(lg) {
    & {
      min-height: calc(100vh - 5.57em);
    }
  }

  .ck-content {
    color: #1f1f1f;
    font-size: 1.2em;
    h1,
    h2,
    h3,
    h4 {
      @extend .text-shadow;
    }
    .image > figcaption {
      font-size: 0.9em;
      background: none;
    }
  }

  @include media-breakpoint-down(xl) {
    & {
      font-size: 1rem;
    }
  }

  @include media-breakpoint-down(lg) {
    & {
      font-size: 1.4vw;
    }
  }

  @include media-breakpoint-down(md) {
    & {
      font-size: 1.8vw;
    }
  }

  @include media-breakpoint-down(sm) {
    & {
      font-size: 2.2vw;
    }
  }

  @include media-breakpoint-down(xs) {
    & {
      font-size: 3vw;
    }
  }
}
