.balance-box {
  padding: 15px 1em;
  background: $c_bg;
  min-height: calc(100vh - 4.5em);

  .all-check {
    display: flex;
    justify-content: flex-end;
  }

  @include media-breakpoint-only(lg) {
    & {
      min-height: calc(100vh - 7.57em);
    }
  }
}
