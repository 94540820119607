.ndvi-box {
  position: relative;
  color: $c_text;
  background-color: $c_bg;
  display: flex;
  height: calc(100vh - 4.5em);

  @include media-breakpoint-only(xs) {
    @supports (-webkit-touch-callout: none) {
      height: calc(100vh - 4.5em - 4.5em);
    }
  }

  @include media-breakpoint-only(lg) {
    & {
      height: calc(100vh - 7.57em);
    }
  }

  .map {
    flex-grow: 1;
    height: 100%;
  }
  .header-text {
    padding: 0em 0.5em;
    color: #000000;
    border-radius: 0.4em;
    background: #ffffff;
    box-shadow: 0 0 0.2em #ffffff;

    position: absolute;
    z-index: 1000;
    top: 1em;
    left: 12em;
    font-size: 1.2em;
    color: $c_text;
    transition: left 200ms linear;
    margin-right: 2em;
    &.center {
      left: 6em;
    }
  }
  .filter-box {
    width: 12em;
    overflow: hidden;
    transition: width 200ms linear;
    &.hide {
      width: 0;
    }
    .filter-content {
      width: 12em;
      padding: 4em 1em 1em 1em;
    }
  }
  .filter-button {
    position: absolute;
    top: 1em;
    left: 1em;
    z-index: 1000;
    display: inline-block;
    padding: 0.2rem 0.5rem;
    background: linear-gradient(180deg, $c_gradient_from, $c_gradient_to);
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    border-color: $c_navlink_border;
    color: white;
    cursor: pointer;

    &:hover {
      background: $c_navlink_hover;
    }

    &.active {
      box-shadow: inset 1px 1px 5px 1px rgba(0, 0, 0, 0.66);
    }
  }
}
