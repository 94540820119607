.model-widget-box {
  .weather-icon-box {
    .weather-icon {
      width: 4em;
    }
  }

  .temp-box {
    .temp-text {
      font-size: 1.2em;
      text-align: center;
    }
  }

  .wind-box {
    .wind-text {
      text-align: center;
    }
  }

  .date-box {
    .date-text {
      text-align: center;
    }
  }
}
