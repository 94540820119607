.service-box {
  .cell-custom {
    padding: 0.2em;
  }
  .cell-left {
    text-align: center;
  }
  .cell-center {
    text-align: center;
    vertical-align: middle;
  }
}
